.App{
  background-color: #feffff;
  height: 768px;
  font-family: 'Poppins', sans-serif;
}

.navbar{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #3DCAB1;
}
.routes{
  display: flex;
    justify-content: center;
    align-items: center;
}
li{
  color: #feffff;
  font-size: 1.25rem;
  padding: 0.35rem 1rem;
  list-style: none;
  text-decoration: none;
}
.search{
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  outline: none;
  background-color: #feffff;
  border: none;
  border-radius: 1rem;
}
li:active{
  background-color: #E4E8E9;
}
